import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youmeet.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/meet_1.svg"
import HeaderImg from "../../assets/img/youMEET/Img1.png"
import Icon1 from "../../assets/img/youMEET/Icon1.png"
import Icon2 from "../../assets/img/youMEET/Icon2.png"
import Icon3 from "../../assets/img/youMEET/Icon3.png"
import Icon4 from "../../assets/img/youMEET/Icon4.png"
import Icon5 from "../../assets/img/youMEET/Icon5.png"
import Icon6 from "../../assets/img/youMEET/Icon6.png"
import Icon7 from "../../assets/img/youMEET/Icon7.png"
import Icon8 from "../../assets/img/youMEET/Icon8.png"
import Icon9 from "../../assets/img/youMEET/Icon9.png"
import Icon10 from "../../assets/img/youMEET/Icon10.png"
import Icon11 from "../../assets/img/youMEET/Icon11.png"
import GroupImg1 from "../../assets/img/youMEET/Img2.png"
import GroupImg2 from "../../assets/img/youMEET/Img3.png"
import TickIcon from "../../assets/img/youMEET/tick.svg"
import SingleForm from "../../components/singleForm"

const YouMAPage = () => {
  return (
    <div>
      <SEO title="Youmeet" keywords={[`youpal`, `youpal group`, `youMeet`]} />
      <div className="youMEETMain products">
        <section className="container">
          <div className="row">
            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                Videoconferencing at its finest
                </h2>
              </div>
            </div>
            <div className="col-12 order-3 order-md-2">
            <p className="internalNarrowCol">
              With Youmeet, you can hold a conference, call your friends or colleagues and be with anyone, anywhere, online. Youmeet is fully supported by audio, dial-in, recording, and simulcasting features. </p>
            </div>
            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-5 productButton d-block mx-auto">
                <span>Free demo</span>
              </button>
              </Link>
            </div>
            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>
          </div>
        </section>

        <section className="container solutionsSection">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Join our virtual meetings room 

            </h3>
            <p className="internalNarrowCol">
            Virtual meetings are made for people to be able to socialize and exchange information, regardless of their location. Youmeet allows people to share data in real-time. Make a call from a room, from the park, and even while sitting on the backseat of the taxi. </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Free Video Colnference</h4>
              <p>
              While there are many other tools on the market, Youmeet offers free video conference with a bunch of perks.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Easy Input</h4>
              <p>
              Creating a new meeting has never been as easy as with Youmeet. All you have to do is come up with the name of the video conference, type it in the URL field, and invite people to join you. 
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>Development Team</h4>
              <p>
              Great progress and process can not be done without a strong team behind. The development team is constantly working on the development and integration of improvements.</p>
            </div>
          </div>
        </section>

        <section className="container internalSection benefitSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                Benefit
                </div>
                <h3 className="text-left">Join a meeting and do not fear anything</h3>
                <p>
                Meeting on Youmeet does not limit you with attendees or time. You can invite as many people as you want and take as much time as you need, without being in a rush. </p>
                <div className="row">
                    <div className="col">
                        <div className="squareHolder"><img src={Icon4} /></div>
                        <h4>Security</h4>
                        <p>
                        Sometimes work meetings can include very sensitive information, yet with Youmeet, security is a top priority.</p>
                    </div>
                    <div className="col">
                        <div className="squareHolder"><img src={Icon5} /></div>
                        <h4>Privacy</h4>
                        <p>
                        We do not require any private information from you to set the meeting, you can stay anonymous throughout the whole time. 
                        </p>
                    </div>
                </div>
                <div className="buttonArea">
                <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>With Youmeet you can never stop discovering</h3>
              <div className="row mt-0 mt-md-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon6} />
                    </div>
                    <div className="col sideContent">
                      <h4>Video passes for every participant </h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Audio passes for every participant</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Good quality of the call and low latency</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Compatibility with WebRTC</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Top-notch video routing </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Ubuntu and Debian for easy installation </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection meetSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">meet and greet</div>
                <h3>Meet people all around the world</h3>
                <p className="mt-0 mt-md-5">
                No matter where you are in the world, reach out to your friends, family or co-workers. Any time, any place.</p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Unlimited in time
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Recording Meeting
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Unlimited in attendiece
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Full audio and video
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Device agnostic
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouMAPage
